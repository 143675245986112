import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { MobileAppVM } from "../shared";
import { setTitle } from "../hooks/setTitle";

const AddEditMobileApp = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const isAddMode = !id;
  const [mobileApp, setMobileApp] = useState(MobileAppVM[0]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(4)
      .max(50)
      .required()
      .matches(new RegExp("^[a-zA-Z_]+$"), "No special characters allowed")
      .trim(),
    friendlyName: yup.string().max(50).required(),
    color: yup.string().max(6).min(6).required(),
    versionCode: yup.number().positive().integer().required(),
    minVersionForce: yup.number().integer().required(),
    isForce: yup.bool(),
  });

  const handleSubmit = (event: any) => {
    if (isAddMode) {
      axios
        .post("/ui/app/create", event)
        .then((res) => {
          navigate("/mobileapps");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .put("/ui/app/update", event)
        .then((res) => {
          navigate("/mobileapps");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      axios
        .get("/ui/app/" + id)
        .then((res) => {
          setMobileApp(res.data);
          setTitle(res.data.friendlyName);
        })
        .catch((err) => {
          console.log(err);
        });
    } else setTitle("Add Mobile App");
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>{isAddMode ? "Add" : "Update"} Mobile App</h2>
          <hr />
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            initialValues={mobileApp}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={!isAddMode}
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />
                    <Form.Text muted hidden={!isAddMode}>
                      Editing now allowed.
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Friendly Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='friendlyName'
                      value={values.friendlyName}
                      onChange={handleChange}
                      isValid={touched.friendlyName && !errors.friendlyName}
                      isInvalid={!!errors.friendlyName}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.friendlyName}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Color</Form.Label>
                    <Form.Control
                      type='text'
                      name='color'
                      value={values.color}
                      onChange={handleChange}
                      isValid={touched.color && !errors.color}
                      isInvalid={!!errors.color}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.color}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Verion Number</Form.Label>
                    <Form.Control
                      type='number'
                      name='versionCode'
                      value={values.versionCode}
                      onChange={handleChange}
                      isValid={touched.versionCode && !errors.versionCode}
                      isInvalid={!!errors.versionCode}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.versionCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Min Version to Force Update</Form.Label>
                    <Form.Control
                      type='number'
                      name='minVersionForce'
                      value={values.minVersionForce}
                      onChange={handleChange}
                      isValid={
                        touched.minVersionForce && !errors.minVersionForce
                      }
                      isInvalid={!!errors.minVersionForce}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.minVersionForce}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group className='mb-3'>
                  <Form.Check
                    name='isForce'
                    label='Force Update'
                    checked={values.isForce}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button type='submit' className='mr-3' disabled={!isValid}>
                  <i className='fa-regular fa-floppy-disk'></i> &nbsp;{" "}
                  {isAddMode ? "Save" : "Update"}
                </Button>
                <Button href='/mobileapps' variant='light'>
                  <i className='fa-solid fa-xmark'></i> &nbsp; Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEditMobileApp;

import axios from "axios";
import { useState, useEffect } from "react";
import {
  Accordion,
  Alert,
  Button,
  Col,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { AgencyVMs } from "../../shared";
import { useParams } from "react-router-dom";
import ControlLink from "../../components/control/ControlLink";
import AgencyRoutes from "../../components/AgencyRoutes";
import ControlMap from "../../components/control/ControlMap";
import { setTitle } from "../../hooks/setTitle";
import ControlCustomDate from "../../components/control/ControlCustomDate";

const AgencyView = () => {
  const { id } = useParams();
  const [agency, setAgency] = useState(AgencyVMs[0]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/agency/" + id)
      .then((res) => {
        setAgency(res.data);
        setTitle(res.data.agencyName);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner animation='border' variant='primary' />
      ) : (
        <>
          <div className='card'>
            <div className='card-body'>
              <h2>
                {agency.agencyName}

                <Button
                  variant='warning'
                  href={"/agencies/edit/" + agency.id}
                  className='pull-right'
                >
                  <i className='fa-regular fa-pen-to-square'></i> Edit
                </Button>
              </h2>
              <br />
              <Row>
                <Col sm={8}>
                  <table>
                    <tbody className='my-table'>
                      {!agency.isActive && (
                        <tr>
                          <td className='text-right pd-r-15'>
                            <strong>Active</strong>
                          </td>
                          <td>
                            <strong className='text-danger'>
                              Agency data not visible to users
                            </strong>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td width={170} className='text-right pd-r-15'>
                          <strong>Onestop Id</strong>
                        </td>
                        <td>
                          <ControlLink
                            displayValue={agency.onestopId}
                            isWebLink={true}
                            link={
                              "https://www.transit.land/feeds/" +
                              agency.onestopId
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Location</strong>
                        </td>
                        <td>
                          {agency.country} / {agency.state} / {agency.city}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Url</strong>
                        </td>
                        <td>
                          <ControlLink
                            displayValue={agency.agencyUrl}
                            isWebLink={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Friendly Name</strong>
                        </td>
                        <td>{agency.agencyFriendlyName}</td>
                      </tr>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Developer Portal</strong>
                        </td>
                        <td>
                          <ControlLink
                            displayValue={agency.developerUrl}
                            isWebLink={true}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Gtfs File</strong>
                        </td>
                        <td>
                          <ControlLink
                            displayValue={agency.gtfsStaticDataUrl}
                            isWebLink={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col sm={4}>
                  <table>
                    <tbody className='my-table'>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Data start at</strong>
                        </td>
                        <td>
                          <ControlCustomDate date={agency.dataStartDate} />
                        </td>
                      </tr>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Data end at</strong>
                        </td>
                        <td>
                          <ControlCustomDate date={agency.dataEndDate} />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td width={170} className='text-right pd-r-15'>
                          <strong>Updated at</strong>
                        </td>
                        <td>
                          <ControlCustomDate date={agency.lastUpdated} />
                        </td>
                      </tr>
                      <tr>
                        <td className='text-right pd-r-15'>
                          <strong>Next update at</strong>
                        </td>
                        <td>
                          <ControlCustomDate date={agency.nextUpdate} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row className='mb-3'>
                <table>
                  <tbody className='my-table'>
                    <tr>
                      <td
                        width={170}
                        className='text-right pd-r-15'
                        style={{ verticalAlign: "top" }}
                      >
                        <strong>Realtime</strong>
                      </td>
                      <td>
                        {agency.isRealTimeDataAvailable ? "Yes" : "No"}

                        {agency.isGtfsRealtimeDataAvailable && (
                          <div>
                            <br />
                            <Accordion
                              defaultActiveKey='0'
                              className={
                                agency.isGtfsRealtimeDataAvailable
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                  <i className='fa-solid fa-wifi text-success'></i>{" "}
                                  &nbsp; Gtfs Real-time
                                </Accordion.Header>
                                <Accordion.Body>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td className='text-right pd-r-15' width={140}>
                                          <strong>Trip Updates:</strong>
                                        </td>
                                        <td>
                                          <ControlLink
                                            displayValue={
                                              agency.gtfsRTTripupdateUrl
                                            }
                                            isWebLink={true}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className='text-right pd-r-15'>
                                          <strong>Vehicle:</strong>
                                        </td>
                                        <td>
                                          <ControlLink
                                            displayValue={
                                              agency.gtfsRTVehicleUrl
                                            }
                                            isWebLink={true}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className='text-right pd-r-15'>
                                          <strong>Alerts:</strong>
                                        </td>
                                        <td>
                                          <ControlLink
                                            displayValue={agency.gtfsRTAlertUrl}
                                            isWebLink={true}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className='text-right pd-r-15'>
                                          <strong>Api Header:</strong>
                                        </td>
                                        <td>{agency.apiKeyHeader}</td>
                                      </tr>
                                      <tr>
                                        <td className='text-right pd-r-15'>
                                          <strong>Key:</strong>
                                        </td>
                                        <td>{agency.apiKey}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className='text-right pd-r-15'
                        style={{ verticalAlign: "top" }}
                      >
                        <strong>License Info</strong>
                      </td>
                      <td>
                        <Accordion>
                          <Accordion.Item eventKey='0'>
                            <Accordion.Header>
                              <i className='fa-solid fa-info text-default'></i>{" "}
                              &nbsp; License
                            </Accordion.Header>
                            <Accordion.Body>
                              <table>
                                <tbody>
                                  <tr>
                                    <td className='text-right pd-r-15'>
                                      <strong>License Url:</strong>
                                    </td>
                                    <td>
                                      <ControlLink
                                        displayValue={agency.licenseUrl}
                                        isWebLink={true}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='text-right pd-r-15'>
                                      <strong>Attribution Text:</strong>
                                    </td>
                                    <td>{agency.licenseAttributionText}</td>
                                  </tr>
                                  <tr>
                                    <td className='text-right pd-r-15'>
                                      <strong>Attribution Instructions:</strong>
                                    </td>
                                    <td>
                                      {agency.licenseAttributionInstructions}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          </div>

          <div className='card'>
            <div className='card-body'>
              <h2>Agency Service</h2>
              <hr />

              <Tabs defaultActiveKey='map' className='mb-3'>
                <Tab eventKey='map' title='Map'>
                  <ControlMap agencies={agency.agencyId} />
                </Tab>
                <Tab eventKey='routes' title='Routes'>
                  {agency.agencyId && (
                    <AgencyRoutes agencyId={agency.agencyId} />
                  )}
                </Tab>
                <Tab eventKey='alerts' title='Service Alerts'>
                  <Alert key='info' variant='warning'>
                    <i className='fa-regular fa-bell'></i>&nbsp; This agency
                    does not provide service alerts.
                  </Alert>
                </Tab>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AgencyView;

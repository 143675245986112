import React, { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { CChart } from "@coreui/react-chartjs";
import { handleApiError } from "../shared";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import ControlCustomDate from "./control/ControlCustomDate";

const InstallTable = () => {
  interface Dashboard {
    startDate: string;
    endDate: string;
    recentInstalls: RecentInstall[];
    charts: Chart[];
  }
  interface RecentInstall {
    id: React.Key;
    color: string;
    dateCreated: string;
    device: string;
    friendlyName: string;
    model: string;
    os: string;
    city: string;
    ip: string;
    userCity: string;
    userState: string;
    userCountry: string;
    userLatitude: string;
    userLongitude: string;
  }
  interface Chart {
    name: string;
    color: string;
    key: string[];
    value: any;
  }

  const [apps, setApps] = useState<Dashboard>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/dashboard?latestInstallCount=200")
      .then((res) => {
        setApps(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Row>
        <Col xs={6}>
          <div className='card'>
            <div className='card-body'>
              {apps?.charts && apps.charts.length > 0 ? (
                <>
                  <CChart
                    type='line'
                    data={{
                      labels: apps?.charts[0].key,
                      datasets: [
                        {
                          label: apps?.charts[0]?.name,
                          backgroundColor: apps?.charts[0]?.color,
                          borderColor: apps?.charts[0]?.color,
                          pointBackgroundColor: apps?.charts[0]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[0]?.value,
                        },
                        {
                          label: apps?.charts[1]?.name,
                          backgroundColor: apps?.charts[1]?.color,
                          borderColor: apps?.charts[1]?.color,
                          pointBackgroundColor: apps?.charts[1]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[1]?.value,
                        },
                        {
                          label: apps?.charts[2]?.name,
                          backgroundColor: apps?.charts[2]?.color,
                          borderColor: apps?.charts[2]?.color,
                          pointBackgroundColor: apps?.charts[2]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[2]?.value,
                        },
                        {
                          label: apps?.charts[3]?.name,
                          backgroundColor: apps?.charts[3]?.color,
                          borderColor: apps?.charts[3]?.color,
                          pointBackgroundColor: apps?.charts[3]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[3]?.value,
                        },
                        {
                          label: apps?.charts[4]?.name,
                          backgroundColor: apps?.charts[4]?.color,
                          borderColor: apps?.charts[4]?.color,
                          pointBackgroundColor: apps?.charts[4]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[4]?.value,
                        },
                        {
                          label: apps?.charts[5]?.name,
                          backgroundColor: apps?.charts[5]?.color,
                          borderColor: apps?.charts[5]?.color,
                          pointBackgroundColor: apps?.charts[5]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[5]?.value,
                        },
                        {
                          label: apps?.charts[6]?.name,
                          backgroundColor: apps?.charts[6]?.color,
                          borderColor: apps?.charts[6]?.color,
                          pointBackgroundColor: apps?.charts[6]?.color,
                          pointBorderColor: "#fff",
                          data: apps?.charts[6]?.value,
                        },
                      ],
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className='card'>
            <div className='card-body'>
              {isLoading ? (
                <Spinner animation='border' variant='primary' />
              ) : (
                <>
                  <i className='fa-brands fa-apple fg-silver' /> :{" "}
                  {apps?.recentInstalls.filter((r) => r.os === "ios").length}
                  &nbsp;&nbsp;&nbsp;{" "}
                  <i className='fa-brands fa-android fg-green' /> :{" "}
                  {apps?.recentInstalls.filter((r) => r.os !== "ios").length}
                  <br />
                  <br />
                  <div style={{ height: "750px", overflowY: "scroll" }}>
                    <Table hover>
                      <thead>
                        <tr>
                          <th style={{ width: "30%" }}>Date</th>
                          <th style={{ width: "30%" }}>Name</th>
                          <th>Selected City</th>
                          <th>User Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apps?.recentInstalls.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <ControlCustomDate date={item.dateCreated} />
                                </td>
                                <td>
                                  {item.os === "ios" ? (
                                    <i className='fa-brands fa-apple fg-silver'></i>
                                  ) : (
                                    <i className='fa-brands fa-android fg-green'></i>
                                  )}
                                  &nbsp;
                                  {item.friendlyName}
                                </td>
                                <td>{item.city}</td>
                                <td>
                                  {item.userCity}, {item.userState},{" "}
                                  {item.userCountry}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InstallTable;

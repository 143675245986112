import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface FuncProps {
  displayValue: string;
  isWebLink: boolean;
  link?: string;
}

const ControlLink: React.FC<FuncProps> = (props) => {
  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      {props.displayValue && props.displayValue.length > 0 && (
        <>
          {props.displayValue} &nbsp;&nbsp;
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip>Click to Copy</Tooltip>}
          >
            <i
              className='fa-solid fa-copy text-warning cursor-hand'
              onClick={(e) => copy(props.displayValue)}
            ></i>
          </OverlayTrigger>
          &nbsp;&nbsp;
          {props.isWebLink && (
            <>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip>Open link</Tooltip>}
              >
                <a
                  href={props.link ? props.link : props.displayValue}
                  target='_blank'
                >
                  <i className='fa-solid fa-link'></i>
                </a>
              </OverlayTrigger>
            </>
          )}
        </>
      )}
    </>
  );
};
export default ControlLink;

import InstallTable from "../components/InstallTable";
import { setTitle } from "../hooks/setTitle";

const DashboardPage = () => {
  setTitle("Dashboard");
  
  return (
    <>
      <InstallTable />
    </>
  );
};
export default DashboardPage;

import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { AgencyVM, RegionMainVM, handleApiError } from "../../shared";
import axios from "axios";
import ControlListAgencies from "../../components/control/ControlListAgencies";
import { useParams } from "react-router-dom";

interface FuncProps {
  agencies: AgencyVM[];
}

const AgenciesByRegion: React.FC<FuncProps> = (props) => {
  const homeName = "Global";
  const [region, setRegion] = useState<RegionMainVM>();
  const [isLoading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(homeName);

  useEffect(() => {
    onClick(null, null, null, null);
  }, []);

  const onClick = (event: any, country: any, state: any, city: any) => {
    if (event) event.preventDefault();
    setLoading(true);

    let header = homeName;

    let url = "ui/region";
    if (country) {
      url += "/" + country;
      header = country;
    }
    if (state) {
      url += "/" + state;
      header = state + ", " + country;
    }
    if (city) {
      url += "/" + city;
      header = city + ", " + state + ", " + country;
    }

    setCurrentLocation(header);

    axios
      .get(url)
      .then((res) => {
        setRegion(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Spinner animation='border' variant='primary' />
      ) : (
        <>
          <Row className='mb-3'>
            <Col xs={8}>
              <h2>{currentLocation}</h2>
              <hr />

              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {region?.regions.map((item, i) => {
                    return (
                      <>
                        <tr key={item.country}>
                          <td>
                            <a
                              onClick={(e) => onClick(e, null, null, null)}
                              href=''
                            >
                              <i className='fa-solid fa-globe'></i>
                            </a>
                            &nbsp; / &nbsp;
                            <a
                              onClick={(e) =>
                                onClick(e, item.country, null, null)
                              }
                              href=''
                            >
                              {item.country}
                            </a>
                            {item.state.length > 0 && (
                              <>
                                &nbsp; / &nbsp;
                                <a
                                  onClick={(e) =>
                                    onClick(e, item.country, item.state, null)
                                  }
                                  href=''
                                >
                                  {item.state}
                                </a>
                              </>
                            )}
                            {item.city.length > 0 && (
                              <>
                                &nbsp; / &nbsp;
                                <a
                                  onClick={(e) =>
                                    onClick(
                                      e,
                                      item.country,
                                      item.state,
                                      item.city
                                    )
                                  }
                                  href=''
                                >
                                  {item.city}
                                </a>
                              </>
                            )}
                          </td>
                          <td>{item.count}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>

          {region && region.agencies.length > 0 && (
            <Row className='mb-3'>
              <Col xs={12}>
                <h5>Agencies</h5>
                <hr />
                <ControlListAgencies
                  agencies={region.agencies}
                  maxShown={2000}    
                  key="byRegion"              
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default AgenciesByRegion;

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import ControlTableSwitch from "../components/control/ControlTableSwitch";
import { handleApiError, MobileAppVM } from "../shared";
import { setTitle } from "../hooks/setTitle";

const MobileAppPage = () => {
  setTitle("Apps");
  const [apps, setApps] = useState(MobileAppVM);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/app")
      .then((res) => {
        setApps(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>
            Apps{" "}
            <Button href='/mobileapps/add' className='pull-right'>
              <i className='fa-solid fa-plus'></i> Add
            </Button>{" "}
          </h2>

          <hr />
          {isLoading ? (
            <Spinner animation='border' variant='primary' />
          ) : (
            <>
              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Version</th>
                    <th>Min Version</th>
                    <th>Force Update</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {apps?.map((item, i) => (
                    <>
                      <tr>
                        <td>
                          <a href={"/mobileapps/edit/" + item.id}>
                            {item.friendlyName}
                          </a>
                        </td>
                        <td>{item.versionCode}</td>
                        <td>{item.minVersionForce}</td>
                        <td>
                          {" "}
                          <ControlTableSwitch status={item.isForce} />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileAppPage;

import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  handleApiError,
  PredictionVM,
} from "../../../shared";

const AgencyBusPrediction = () => {
  const { agency, route, routeType, stopId, stopName } = useParams();
  const [predictions, setPredictions] = useState(PredictionVM);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(
        `v1/transitdata/getPredictions?&agency=${agency}&routeType=${routeType}&stopId=${stopId}`
      )
      .then((res) => {
        setPredictions(res.data);
      })
      .catch((err) => {
        handleApiError(err);
        setError(true);
      });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className='card'>
            <div className='card-body'>
              <h2>{stopName}</h2>
              <hr />   
              {error?"No data":""}
              {predictions.map((item, i) => {
                return (
                  <>
                    <Col xs={4} className='prediction'>
                      <h5>
                        {item.routeBranch}
                      </h5>
                      <p>-- {item.fancyTowards}</p>
                      <h6 className={item.layout == 0 ? "realtime" : ""}>
                        {item.minutes[0]} min
                        {(() => {
                          if (item.minutes.length > 1) {
                            return <>, {item.minutes[1]} min</>
                          }
                        })()}
                         {(() => {
                          if (item.minutes.length > 2) {
                            return <>, {item.minutes[2]} min</>
                          }
                        })()}
                      </h6>
                    </Col>
                  </>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AgencyBusPrediction;

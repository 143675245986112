import axios from "axios";
import { FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { CityUiVM, Countries } from "../shared";
import { Toast } from "react-bootstrap";
import { setTitle } from "../hooks/setTitle";

const AddEditCity = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const isAddMode = !id;
  const [mobileApp, setMobileApp] = useState(CityUiVM[0]);
  if (isAddMode) {
    // do this to remove default object
    mobileApp.transitAgencies = [];
  }
  const [agencies, setAgencies] = useState(CityUiVM[0].transitAgencies);
  const [selectedAgency, setSelectedAgency] = useState(0);
  const [showToast, setShowToast] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().min(2).max(50).required().trim(),
    state: yup.string().max(50).required(),
    country: yup.string().max(50).required(),
    lat: yup.number().required(),
    lon: yup.number().required(),
    minVersion: yup.number(),
    isActive: yup.bool(),
    isTripPlannerAvailable: yup.bool(),
    isPopular: yup.bool(),
  });

  const handleSubmit = (event: any) => {
    if (isAddMode) {
      axios
        .post("/ui/city/create", event)
        .then((res) => {
          navigate("/cities");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .put("/ui/city/update", event)
        .then((res) => {
          navigate("/cities");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios
      .get("/ui/transitagency/")
      .then((res) => {
        setAgencies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    if (!isAddMode) {
      axios
        .get("/ui/city/" + id)
        .then((res) => {
          setMobileApp(res.data);
          setTitle(res.data.name + ", " + res.data.country);
        })
        .catch((err) => {
          console.log(err);
        });
    } else setTitle("Add City");
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>{isAddMode ? "Add" : "Update"} City</h2>
          <hr />
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            initialValues={mobileApp}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col xs={7}>
                    {" "}
                    <Row className='mb-3'>
                      <Form.Group as={Col} xs='6'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type='text'
                          disabled={!isAddMode}
                          name='name'
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name}
                          isInvalid={!!errors.name}
                        />
                        <Form.Text muted hidden={!isAddMode}>
                          Editing now allowed.
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs='3'>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type='text'
                          name='state'
                          value={values.state}
                          onChange={handleChange}
                          isValid={touched.state && !errors.state}
                          isInvalid={!!errors.state}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.state}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs='3'>
                        <Form.Label>Country</Form.Label>

                        <Form.Select
                          name='country'
                          value={values.country}
                          onChange={handleChange}
                        >
                          {Countries.map((item, i) => {
                            return (
                              <>
                                <option value={item.iso2}>{item.name}</option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                      <Form.Group as={Col} xs='6'>
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control
                          type='number'
                          name='lat'
                          value={values.lat}
                          onChange={handleChange}
                          isValid={touched.lat && !errors.lat}
                          isInvalid={!!errors.lat}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.lat}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs='6'>
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control
                          type='number'
                          name='lon'
                          value={values.lon}
                          onChange={handleChange}
                          isValid={touched.lon && !errors.lon}
                          isInvalid={!!errors.lon}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.lon}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className='mb-6'>
                      <Form.Group as={Col} xs='6'>
                        <Form.Label>Min Version</Form.Label>
                        <Form.Control
                          type='number'
                          name='minVersion'
                          value={values.minVersion}
                          onChange={handleChange}
                          isValid={touched.minVersion && !errors.minVersion}
                          isInvalid={!!errors.minVersion}
                        />
                        <Form.Text className='text-muted'>
                          City only visible to app running higher or same
                          version specified above.
                        </Form.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.minVersion}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs='6'>
                        <Form.Check
                          type='switch'
                          name='isActive'
                          checked={values.isActive}
                          onChange={handleChange}
                          label='Active'
                        />

                        <Form.Check
                          type='switch'
                          name='isTripPlannerAvailable'
                          disabled={true}
                          checked={values.isTripPlannerAvailable}
                          label='Trip Planner'
                        />

                        <Form.Check
                          type='switch'
                          name='isPopular'
                          checked={values.isPopular}
                          onChange={handleChange}
                          label='Popular (Shows on top)'
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col xs={5}>
                    <h6>Agencies</h6>
                    <hr />

                    <FieldArray
                      name='transitAgencies'
                      render={(arrayHelpers: any) => (
                        <>
                          <Row className='mb-3'>
                            <Form.Group as={Col} xs='6'>
                              <InputGroup className='mb-3'>
                                <Form.Select
                                  onChange={(e) => {
                                    setSelectedAgency(Number(e.target.value));
                                  }}
                                  value={selectedAgency}
                                >
                                  <option disabled value='0'>
                                    None
                                  </option>
                                  {agencies.map((item, i) => {
                                    return (
                                      <>
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </Form.Select>
                                <Button
                                  variant='outline-secondary'
                                  onClick={(e) => {
                                    if (selectedAgency == 0) return;

                                    if (
                                      values.transitAgencies.filter(
                                        (r) => r.id == selectedAgency
                                      ).length > 0
                                    ) {
                                      setShowToast(true);
                                      return;
                                    }

                                    arrayHelpers.push({
                                      id: selectedAgency,
                                      name: agencies.filter(
                                        (r) => r.id == selectedAgency
                                      )[0].name,
                                    });
                                  }}
                                >
                                  Add
                                </Button>
                              </InputGroup>
                              <Form.Text muted>
                                Showing active agencies.
                              </Form.Text>
                              <Toast
                                onClose={() => setShowToast(false)}
                                show={showToast}
                                delay={5000}
                                autohide
                              >
                                <Toast.Body className='text-danger'>
                                  Oops, agency already exist.
                                </Toast.Body>
                              </Toast>
                            </Form.Group>
                            <Form.Group as={Col} xs='6'>
                              <ul className='list-group'>
                                {values.transitAgencies.map((item, index) => {
                                  return (
                                    <>
                                      <li
                                        className='list-group-item'
                                        key={index}
                                      >
                                        {item.name}
                                        <a
                                          href='#'
                                          className='pull-right text-danger'
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          X
                                        </a>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </Form.Group>
                          </Row>
                        </>
                      )}
                    />
                  </Col>
                </Row>

                <Button type='submit' className='mr-3' disabled={!isValid}>
                  <i className='fa-regular fa-floppy-disk'></i> &nbsp;{" "}
                  {isAddMode ? "Save" : "Update"}
                </Button>
                <Button href='/cities' variant='light'>
                  <i className='fa-solid fa-xmark'></i> &nbsp; Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEditCity;

import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { handleApiError, StopVM } from "../../../shared";

const AgencyRouteStop = () => {
  const { agency, route,routeType,  towards, directionId } = useParams();
  const [stops, setStops] = useState(StopVM);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    axios
      .get(
        `v1/transitdata/getStops?route=${route}&today=false&agency=${agency}&direction=${towards}&includePath=false&directionId=${directionId}`
      )
      .then((res) => {
        setStops(res.data);
      })
      .catch((err) => {
        handleApiError(err);
        setError(true);
      });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className='card'>
            <div className='card-body'>
              <h2>{route} - {towards}</h2>
              <hr />
              {error?"No data":""}
              <Table hover>
                <tbody>
                  {stops.stops.map((item, i) => {
                    return (
                      <>
                        <tr>
                            <td>
                            <a href={`/predictions/${agency}/${route}/${routeType}/${item.stop_id}/${encodeURIComponent(item.stop_name)}`}>{item.stop_name}</a>
                            </td>                          
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AgencyRouteStop;

import AddEditAgency from "./components/AddEditAgency";
import AddEditCity from "./components/AddEditCity";
import AddEditMap from "./components/AddEditMap";
import AddEditMobileApp from "./components/AddEditMobileApp";
import AgencyPage from "./pages/agency/AgencyPage";
import CityPage from "./pages/CityPage";
import DashboardPage from "./pages/DashboardPage";
import MapsPage from "./pages/MapsPage";
import MobileAppPage from "./pages/MobileAppPage";
import AgencyRouteDirection from "./pages/dataview/direction/AgencyRouteDirection";
import AgencyRouteStop from "./pages/dataview/stops/AgencyRouteStop";
import AgencyBusPrediction from "./pages/dataview/prediction/AgencyBusPrediction";
import OtpInstancePage from "./pages/OtpInstancePage";
import AddEditOtpInstance from "./components/AddEditOtpInstance";
import AgencyView from "./pages/agency/AgencyView";
import JobPage from "./pages/JobPage";

const routes = [
  {
    name: "Dashbaord",
    path: "/",
    exact: true,
    element: DashboardPage,
    showInBar: false
  },
  {
    name: "Apps",
    path: "/mobileapps",
    exact: true,
    element: MobileAppPage,
    showInBar: true
  },
  {
    name: "Add",
    path: "/mobileapps/add",
    exact: true,
    element: AddEditMobileApp,
    showInBar: false
  },
  {
    name: "Edit",
    path: "/mobileapps/edit/:id",
    exact: true,
    element: AddEditMobileApp,
    showInBar: false
  },
  {    
    name: "Cities",
    path: "/cities",
    exact: true,
    element: CityPage,
    showInBar: true
  },
  {
    name: "Cities",
    path: "/cities/add",
    exact: true,
    element: AddEditCity,
    showInBar: false
  },
  {
    name: "Cities",
    path: "/cities/edit/:id",
    exact: true,
    element: AddEditCity,
    showInBar: false
  },
  {
    name: "Transit Map",
    path: "/maps",
    exact: true,
    element: MapsPage,
    showInBar: true
  },
  {
    name: "Transit Map",
    path: "/maps/add",
    exact: true,
    element: AddEditMap,
    showInBar: false
  },
  {
    name: "Transit Map",
    path: "/maps/edit/:id",
    exact: true,
    element: AddEditMap,
    showInBar: false
  },
  {
    name: "Otp Instance",
    path: "/otpinstance",
    exact: true,
    element: OtpInstancePage,
    showInBar: true
  },
  {
    name: "Otp Instance",
    path: "/otpinstance/add",
    exact: true,
    element: AddEditOtpInstance,
    showInBar: false
  },
  {
    name: "Otp Instance",
    path: "/otpinstance/edit/:id",
    exact: true,
    element: AddEditOtpInstance,
    showInBar: false
  },
  {
    name: "Agencies",
    path: "/agencies",
    exact: true,
    element: AgencyPage,
    showInBar: true
  },
  {
    name: "Agencies",
    path: "/agencies/add",
    exact: true,
    element: AddEditAgency,
    showInBar: false
  },
  {
    name: "Agencies",
    path: "/agencies/edit/:id",
    exact: true,
    element: AddEditAgency,
    showInBar: false
  },
  {
    name: "Agencies",
    path: "/agencies/view/:id",
    exact: true,
    element: AgencyView,
    showInBar: false
  },
  // {
  //   name: "Routes",
  //   path: "/routes/:agency",
  //   exact: true,
  //   element: AgencyRoutes,
  //   showInBar: false
  // },
  {
    name: "Direction",
    path: "/direction/:agency/:route/:routeType",
    exact: true,
    element: AgencyRouteDirection,
    showInBar: false
  },
  {    
    name: "Stop",
    path: "/stops/:agency/:route/:routeType/:towards/:directionId",
    exact: true,
    element: AgencyRouteStop,
    showInBar: false
  },
  {
    name: "Prediction",
    path: "/predictions/:agency/:route/:routeType/:stopId/:stopName",
    exact: true,
    element: AgencyBusPrediction,
    showInBar: false
  },
  {
    name: "Jobs",
    path: "/jobs",
    exact: true,
    element: JobPage,
    showInBar: true
  },
];

export default routes;

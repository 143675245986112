import { number } from "yup/lib/locale";

// api error handler
export const handleApiError = (error: any) => {
  console.log(error);
  if (error && error.response && error.response.status == 401) {
    window.location.href = "/login";
  }
};

export const logOut = () => {
  localStorage.removeItem("token");
  window.location.href = "/login";
};

export const MobileAppVM = [
  {
    id: 0,
    name: "",
    friendlyName: "",
    color: "000000",
    versionCode: 0,
    minVersionForce: 0,
    isForce: false,
  },
];

export const AgencyVMs = [
  {
    id: 0,
    agencyId: "",
    agencyIdGtfs: "",
    agencyName: "",
    agencyUrl: "",
    agencyTimezone: "",
    agencyLang: "",
    agencyPhone: "",
    agencyFareUrl: "",
    agencyEmail: "",
    agencyColor: "",
    agencyTextColor: "",
    agencyFriendlyName: "",
    datasource: 2,
    gtfsStaticDataUrl: "",
    gtfsStaticDataUrlAuth: 0,
    dataEndDate: "",
    dataStartDate: "",
    lastUpdated: "",
    lastUpdateAttempt: "",
    nextUpdate: "",
    status: 0,
    isRealTimeDataAvailable: false,
    isRealTimeVehicleDataAvailable: false,
    isGtfsRealtimeDataAvailable: false,
    gtfsRealtimeDataAuth: 0,
    gtfsRTTripupdateUrl: "",
    gtfsRTVehicleUrl: "",
    gtfsRTAlertUrl: "",
    isRouteServiceAlertAvailable: false,
    apiKeyHeader: "",
    apiKey: "",
    isForceDataUpdateNow: false,
    isUpdateGtfsProcesssedFile: false,
    isCustomTransitAlert: false,
    twitterId: "",
    otpInstanceId: 0,
    otpInstanceName: "",
    country: "",
    state: "",
    city: "",
    developerUrl: "",
    licenseUrl: "",
    licenseCommercialUseAllowed: 0,
    licenseShareAlikeOptional: 0,
    licenseCreateDerivedProduct: 0,
    licenseRedistributionAllowed: 0,
    licenseUseWithoutAttribution: 0,
    licenseAttributionText: "",
    licenseAttributionInstructions: "",
    latitude: 0.0,
    longitude: 0.0,
    onestopId: "",
    isActive: false,
    isAgencyIdActive: false,
  },
];

export enum AuthType {
  None = 0,
  QueryString = 1,
  Header = 2,
  Both = 3,
}

export interface AgencyVM {
  id: 0;
  agencyId: "";
  agencyIdGtfs: "";
  agencyName: "";
  agencyUrl: "";
  agencyTimezone: "";
  agencyLang: "";
  agencyPhone: "";
  agencyFareUrl: "";
  agencyEmail: "";
  agencyColor: "";
  agencyTextColor: "";
  agencyFriendlyName: "";
  datasource: 2;
  gtfsStaticDataUrl: "";
  dataEndDate: "";
  dataStartDate: "";
  lastUpdated: "";
  lastUpdateAttempt: "";
  nextUpdate: "";
  status: 0;
  isRealTimeDataAvailable: false;
  isRealTimeVehicleDataAvailable: false;
  isGtfsRealtimeDataAvailable: false;
  gtfsRTTripupdateUrl: "";
  gtfsRTVehicleUrl: "";
  gtfsRTAlertUrl: "";
  isRouteServiceAlertAvailable: false;
  apiKeyHeader: "";
  apiKey: "";
  isForceDataUpdateNow: false;
  isCustomTransitAlert: false;
  twitterId: "";
  otpInstanceId: 0;
  otpInstanceName: "";
  country: "";
  state: "";
  city: "";
  developerUrl: "";
  licenseUrl: "";
  licenseCommercialUseAllowed: 0;
  licenseShareAlikeOptional: 0;
  licenseCreateDerivedProduct: 0;
  licenseRedistributionAllowed: 0;
  licenseUseWithoutAttribution: 0;
  licenseAttributionText: "";
  licenseAttributionInstructions: "";
  latitude: 0.0;
  longitude: 0.0;
  onestopId: "";
  isActive: false;
}

export const AgencyLicense = ["Unknown", "Yes", "No", "Exclude_no"];

export const CityUiVM = [
  {
    id: 0,
    name: "",
    state: "",
    country: "CA",
    lat: 0,
    lon: 0,
    minVersion: 0,
    isActive: true,
    isTripPlannerAvailable: false,
    isPopular: false,
    transitAgencies: [
      {
        id: 0,
        name: "",
        timezone: "",
      },
    ],
  },
];

export const MapVM = [
  {
    id: 0,
    agencyId: 0,
    agencyName: "",
    name: "",
    fileName: "",
    downloadUrl: "",
    signedDownloadUrl: "",
  },
];

export const Routes = [
  {
    route_id: "",
    agency_id: 0,
    route_short_name: "",
    route_long_name: "",
    route_type: 0,
    route_color: "",
    route_text_color: "",
    agency_name: "",
    sanitized_route_short_name: "",
    is_verified: false,
    original_route_short_name: "",
    original_route_long_name: "",
    original_route_color: "",
    original_route_text_color: "",
  },
];

export const DirectionVM = [
  {
    direction_id: 0,
    route_short_name: "",
    routeBranch: "",
    trip_head_sign: "",
    fancyTowards: "",
  },
];

export const StopVM = {
  stops: [
    {
      stop_id: "",
      stop_code: "",
      stop_name: "",
      stop_lat: 0.0,
      stop_lon: 0.0,
      location_type: 0,
    },
  ],
};

export const PredictionVM = [
  {
    route: "",
    routeTitle: "",
    displayName: "",
    towards: "",
    fancyTowards: "",
    direction: 1,
    route_type: 3,
    route_color: "",
    route_text_color: "",
    minutes: [],
    trips: [],
    blocks: [],
    vehicles: [],
    crowdLevels: [],
    layout: 0,
    canTrackBuses: true,
    routeBranch: "",
    agency: "",
    agency_color: "",
    agency_text_color: "",
    agency_friendly_name: "",
  },
];

export const OtpInstanceVM = [
  {
    id: 0,
    name: "",
    timezone: "",
    url: "",
    dateCreated: "2023-06-01T15:53:46.92Z", // need some fake date or create will fail
    otpMemory: 0,
    graphMemory: 0,
    description: "",
    isActive: false,
    isOtpRestartRequired: false,
    isGraphRebuildRequired: false,
    isStreetGraphRebuildRequired: false,
    isOsmRebuildRequired: false,
    isProductionReady: false,
    clusterUrl: "https://tripplanner-us.anilvasani.com",

    transitAgencies: [
      {
        id: 0,
        agencyId: "",
        name: "",
        timezone: "",
      },
    ],
    osms: [
      {
        id: 0,
        otpInstanceId: 0,
        url: "",
        cropParam: "",
      },
    ],
  },
];

export interface RegionVM {
  country: string;
  state: string;
  city: string;
  count: number;
}

export interface RegionMainVM {
  regions: RegionVM[];
  agencies: AgencyVM[];
}

export interface JobVM {
  id: 0;
  dateCreated: "";
  dateUpdated: "";
  name: "";
  type: "";
  dateStart: "";
  dateExpire: "";
  status: "";
  insertedBy: "";
  updatedBy: "";
  statusDetail: null;
  entityId: 0;
}

export const Countries = [
  {
    iso2: "AU",
    name: "Australia",
  },
  {
    iso2: "CA",
    name: "Canada",
  },
  {
    iso2: "DE",
    name: "Germany",
  },
  {
    iso2: "FR",
    name: "France",
  },
  {
    iso2: "GB",
    name: "United Kingdom",
  },
  {
    iso2: "IN",
    name: "India",
  },
  {
    iso2: "IE",
    name: "Ireland",
  },
  {
    iso2: "MX",
    name: "Mexico",
  },
  {
    iso2: "NZ",
    name: "New Zealand",
  },
  {
    iso2: "UE",
    name: "United Arab Emirates",
  },
  {
    iso2: "US",
    name: "United States of America",
  },
  {
    iso2: "ZA",
    name: "South Africa",
  },
  {
    iso2: "CH",
    name: "Switzerland",
  },
  {
    iso2: "ES",
    name: "Spain",
  },
];

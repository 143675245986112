import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import { AgencyVM, AgencyVMs, handleApiError } from "../../shared";
import ControlListAgencies from "../../components/control/ControlListAgencies";
import AgenciesByRegion from "./AgenciesByRegion";
import { setTitle } from "../../hooks/setTitle";

const AgencyPage = () => {
  setTitle("Agencies");
  
  const [agencies, setAgencies] = useState<AgencyVM[]>([]);

  const [agenciesWithRoute, setAgenciesWithRoute] = useState<AgencyVM[]>([]);
  const [agenciesExpired, setAgenciesExpired] = useState<AgencyVM[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingAgencies, setLoadingAgencies] = useState(true);
  const [isLoadingRoutes, setLoadingRoutes] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/agency")
      .then((res) => {
        setAgencies(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoadingAgencies(false);
      });
    axios
      .get("/ui/agency/routeverificationrequired")
      .then((res) => {
        setAgenciesWithRoute(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoadingRoutes(false);
      });
    axios
      .get("/ui/agency/expired")
      .then((res) => {
        setAgenciesExpired(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>
            Agencies
            <Button href='/agencies/add' className='pull-right'>
              <i className='fa-regular fa-plus'></i> Add
            </Button>
          </h2>
          <hr />

          <Tabs defaultActiveKey='routes' className='mb-3'>
            <Tab eventKey='expired' title='Expired'>
              <p>
                This tab shows agencies which data is expired or about to
                expire. This doens't show inactive agencies.
              </p>
              {isLoading ? (
                <Spinner animation='border' variant='primary' />
              ) : (
                <>
                  <ControlListAgencies
                    agencies={agenciesExpired}
                    maxShown={15}
                    key='expired'
                  />
                </>
              )}
            </Tab>
            <Tab eventKey='all' title='All'>
              <p>This doens't show inactive agencies.</p>
              {isLoadingAgencies ? (
                <Spinner animation='border' variant='primary' />
              ) : (
                <>
                  <ControlListAgencies
                    agencies={agencies.filter((r) => r.isActive)}
                    maxShown={15}
                    key='all'
                  />
                </>
              )}
            </Tab>
            <Tab eventKey='routes' title='Routes Updated'>
              <p>
                This tab shows agencies where routes are updated and needs to
                look at manually. This also shows inactive agencies.
              </p>
              {isLoadingRoutes ? (
                <Spinner animation='border' variant='primary' />
              ) : (
                <>
                  <ControlListAgencies
                    agencies={agenciesWithRoute}
                    maxShown={15}
                    key='withRoute'
                  />
                </>
              )}
            </Tab>
            <Tab eventKey='region' title='Region'>
              <p>This tab shows agencies by region.</p>
              <AgenciesByRegion agencies={agencies} />
            </Tab>
            <Tab eventKey='inactive' title='Inactive'>
              <p>
                This tab shows inactive agencies. Agencies are inactive when
                added.
              </p>
              {isLoadingAgencies ? (
                <Spinner animation='border' variant='primary' />
              ) : (
                <>
                  <ControlListAgencies
                    agencies={agencies.filter((r) => r.isActive === false)}
                    maxShown={15}
                    key='inactive'
                  />
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default AgencyPage;

import axios from "axios";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  InputGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { handleApiError, Routes } from "../shared";
import { FieldArray, Formik } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";

interface FuncProps {
  agencyId: string;
}

const AgencyRoutes: React.FC<FuncProps> = (props) => {
  const [routes, setRoutes] = useState(Routes);
  const [isLoading, setLoading] = useState(true);

  const getRoutes = () => {
    setLoading(true);
    axios
      .get("/ui/route/" + props.agencyId)
      .then((res) => {
        setRoutes(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoutes();
  }, []);

  const handleSubmit = (event: any) => {
    setLoading(true);
    axios
      .put("/ui/route/update", event.routesValue)
      .then((res) => {
        getRoutes();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const invertTextColors = (e: any, routesValue: any[]) => {
    e.preventDefault();
    routesValue.map((item, i) => {
      if (item.route_text_color == "FFFFFF") item.route_text_color = "000000";
      else item.route_text_color = "FFFFFF";
    });
  };

  const getRouteImage = (routeType: number) => {
    if (routeType === 0 || (routeType >= 900 && routeType < 1000)) {
      return "/images/ic_streetcar.png";
    } else if (routeType === 1 || (routeType >= 400 && routeType < 500)) {
      return "/images/ic_subway.png";
    } else if (routeType === 2 || (routeType >= 100 && routeType < 200)) {
      return "/images/ic_train.png";
    } else if (
      routeType === 4 ||
      (routeType >= 1000 && routeType < 1100) ||
      (routeType >= 1200 && routeType < 1300)
    ) {
      return "/images/ic_boat.png";
    } else if (routeType === 5 || (routeType >= 1300 && routeType < 1400)) {
      return "/images/ic_cable_car.png";
    } else if (
      routeType === 6 ||
      routeType === 7 ||
      (routeType >= 1400 && routeType < 1500)
    ) {
      return "/images/ic_funicular.png";
    } else if (routeType === 11 || (routeType >= 800 && routeType < 900)) {
      return "/images/ic_trolleybus.png";
    } else if (routeType === 12) {
      return "/images/ic_monorail.png";
    } else if (routeType >= 1100 && routeType < 1200) {
      return "/images/ic_helicopter.png";
    } else if (routeType >= 1500 && routeType < 1600) {
      return "/images/ic_taxi.png";
    } else if (routeType >= 1700 && routeType < 1800) {
      return "/images/ic_carriage.png";
    } else if (routeType >= 200 && routeType < 300) {
      return "/images/ic_coachbus.png";
    } else if (routeType === 712 || routeType === 713) {
      return "/images/ic_schoolbus.png";
    } else {
      return "/images/ic_bus.png";
    }
  };

  const schema = yup.object().shape({
    routesValue: yup.array().of(
      yup.object().shape({
        sanitized_route_short_name: yup.string().min(1).required(),
        route_color: yup
          .string()
          .min(6)
          .max(6)
          .notOneOf([yup.ref("route_text_color"), "000000", "FFFFFF", "ffffff"])
          .required(),
        route_text_color: yup.string().required(),
      })
    ),
  });

  return (
    <>
      {isLoading ? <Spinner animation='border' variant='primary' /> : ""}
      <Row>
        <Col xs={12}>
          {routes.length > 0 ? (
            <Formik
              validationSchema={schema}
              enableReinitialize={true}
              initialValues={{ routesValue: routes }}
              onSubmit={handleSubmit}
              validateOnChange={false}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                  <FieldArray
                    name='routesValue'
                    render={(arrayHelpers: any) => (
                      <div>
                        <Row className='mb-3'>
                          <Col xs={4} className='text-primary'>
                            <i className='fa-solid fa-info'></i> &nbsp; shows
                            original data from agency.
                          </Col>
                          <Col xs={2}></Col>
                          <Col xs={3}></Col>
                          <Col xs={2}></Col>
                          <Col xs={1}>
                            <Button
                              variant='link'
                              onClick={(e) =>
                                invertTextColors(e, values.routesValue)
                              }
                            >
                              Invert
                            </Button>
                          </Col>
                        </Row>

                        {values.routesValue.map((item, index) => (
                          <div key={index}>
                            <Row className='mb-3'>
                              <Col xs={4}>
                                <span className='route-status'>
                                  {item.is_verified ? (
                                    <i className='fa-solid fa-circle-check text-success'></i>
                                  ) : (
                                    <i className='fa-solid fa-circle-xmark text-danger'></i>
                                  )}
                                </span>
                                <img
                                  src={getRouteImage(item.route_type)}
                                  alt={item.route_long_name}
                                  className='route-icon'
                                />
                                <a
                                  href={`/direction/${props.agencyId}/${item.route_short_name}/${item.route_type}`}
                                  className='route'
                                  style={{
                                    background: "#" + item.route_color,
                                    color: "#" + item.route_text_color,
                                  }}
                                >
                                  {item.sanitized_route_short_name}
                                </a>
                                &nbsp;&nbsp;
                                {item.route_long_name}
                              </Col>
                              <Col xs={2}>
                                <InputGroup>
                                  <Form.Control
                                    type='text'
                                    minLength={1}
                                    name={`routesValue.${index}.sanitized_route_short_name`}
                                    value={item.sanitized_route_short_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.routesValue?.[index]}
                                  />

                                  <OverlayTrigger
                                    placement='right'
                                    overlay={
                                      <Tooltip>
                                        {item.original_route_short_name}
                                      </Tooltip>
                                    }
                                  >
                                    <InputGroup.Text>
                                      <i className='fa-solid fa-info'></i>
                                    </InputGroup.Text>
                                  </OverlayTrigger>
                                </InputGroup>
                              </Col>
                              <Col xs={3}>
                                <InputGroup>
                                  <Form.Control
                                    type='text'
                                    name={`routesValue.${index}.route_long_name`}
                                    value={item.route_long_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.routesValue?.[index]}
                                  />

                                  <OverlayTrigger
                                    placement='right'
                                    overlay={
                                      <Tooltip>
                                        {item.original_route_long_name}
                                      </Tooltip>
                                    }
                                  >
                                    <InputGroup.Text>
                                      <i className='fa-solid fa-info'></i>
                                    </InputGroup.Text>
                                  </OverlayTrigger>
                                </InputGroup>
                              </Col>
                              <Col xs={2}>
                                <InputGroup>
                                  <Form.Control
                                    type='text'
                                    name={`routesValue.${index}.route_color`}
                                    value={item.route_color}
                                    onChange={handleChange}
                                    isInvalid={!!errors.routesValue?.[index]}
                                  />
                                  <OverlayTrigger
                                    placement='right'
                                    overlay={
                                      <Tooltip>
                                        {item.original_route_color}
                                      </Tooltip>
                                    }
                                  >
                                    <InputGroup.Text>
                                      <i className='fa-solid fa-info'></i>
                                    </InputGroup.Text>
                                  </OverlayTrigger>
                                </InputGroup>
                              </Col>
                              <Col xs={1}>
                                <Form.Select
                                  name={`routesValue.${index}.route_text_color`}
                                  value={item.route_text_color}
                                  onChange={handleChange}
                                >
                                  <option value='FFFFFF'>- W -</option>
                                  <option value='000000'>- B -</option>
                                </Form.Select>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <hr />
                  <Button
                    type='submit'
                    className='mr-3'
                    disabled={isLoading || !isValid}
                  >
                    {isLoading ? (
                      <Spinner animation='border' variant='light' size='sm' />
                    ) : (
                      <>
                        <i className='fa-regular fa-floppy-disk'></i> &nbsp;
                        Save
                      </>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <Alert key='info' variant='warning'>
                <i className='fa-solid fa-route'></i> &nbsp; No routes available
              </Alert>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default AgencyRoutes;

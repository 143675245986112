import React, { useEffect, useState } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Config } from "../../config";

interface FuncProps {
  agencies: string;
  polygons?: string[];
}

mapboxgl.accessToken = Config.MAPBOX_ACCESS_TOKEN;

let map: mapboxgl.Map | null = null;

const ControlMap: React.FC<FuncProps> = (props) => {
  const [mapCenter, setMapCenter] = useState({ lat: 37.0902, lng: -95.7129 });
  let isOutlineLoaded = false;

  useEffect(() => {
    map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/anilvasani/clug0aa1f016f01qf96j11l1b",
      zoom: 10,
      center: [mapCenter.lng, mapCenter.lat],
    });

    map.addControl(new mapboxgl.NavigationControl());
    map.scrollZoom.enable();

    map.on("style.load", () => {
      // map?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
      // map?.setConfigProperty("basemap", "showPlaceLabels", false);
      // map?.setConfigProperty("basemap", "showTransitLabels", false);
      // map?.setConfigProperty("basemap", "showRoadLabels", false);
    });

    map.on("load", () => {
      if (map?.isStyleLoaded()) {
        getRouteCoordinates();
        if (props.polygons) drawSquareArea(props.polygons);
      }
    });
  }, []);

  const getRouteCoordinates = () => {
    axios
      .get("/ui/route/coordinates?routeTypes=-1&agencies=" + props.agencies)
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data[0].path.length > 0) {
          console.log(map?.isStyleLoaded());

          map?.setCenter([res.data[0].path[0].lon, res.data[0].path[0].lat]);

          res.data.forEach((route: any) => {
            drawRoute(route);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const drawRoute = (route: any) => {
    const sourceId = crypto.randomUUID();
    map?.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: route.path.map((c: any) => [c.lon, c.lat]),
        },
      },
    });
    map?.addLayer({
      id: sourceId,
      type: "line",
      source: sourceId,
      paint: {
        "line-color": "#" + route.color,
        "line-width": 2,
      },
    });
  };

  const drawSquareArea = (polygons: string[]) => {
    for (var data of polygons) {
      if (data && data.length > 0) {
        var multiSplitted = data.split(";");
        for (var item of multiSplitted) {
          var splitted = item.split(",");
          var bounds = [
            [parseFloat(splitted[0]), parseFloat(splitted[1])], // Southwest coordinates
            [parseFloat(splitted[2]), parseFloat(splitted[3])], // Northeast coordinates
          ];

          const sourceId = crypto.randomUUID();
          map?.addSource(sourceId, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [bounds[0][0], bounds[0][1]],
                    [bounds[0][0], bounds[1][1]],
                    [bounds[1][0], bounds[1][1]],
                    [bounds[1][0], bounds[0][1]],
                    [bounds[0][0], bounds[0][1]],
                  ],
                ],
              },
            },
          });

          map?.addLayer({
            id: sourceId,
            type: "fill",
            source: sourceId,
            paint: {
              "fill-color": "#0B7FAB",
              "fill-opacity": 0.2,
            },
          });
        }
      }
    }
  };

  return (
    <>
      <div className='map-container' id='map'></div>
    </>
  );
};

export default ControlMap;

import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { DirectionVM, handleApiError } from "../../../shared";

const AgencyRouteDirection = () => {
  const { agency, route, routeType } = useParams();
  const [directions, setDirections] = useState(DirectionVM);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(
        `v1/transitdata/getDirections?route=${route}&today=false&agency=${agency}`
      )
      .then((res) => {
        setDirections(res.data);
      })
      .catch((err) => {
        handleApiError(err);
        setError(true);
      });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className='card'>
            <div className='card-body'>
              <h2>{route} - Choose Direction</h2>
              <hr />
              {error?"No data":""}
              <Table hover>
                <tbody>
                  {directions.map((item, i) => {
                    return (
                      <>
                        <tr>
                            <td>
                            <a href={`/stops/${agency}/${route}/${routeType}/${encodeURIComponent(item.trip_head_sign)}/${item.direction_id}`}>{item.fancyTowards}</a>
                            </td>                          
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AgencyRouteDirection;

import moment from "moment";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { handleApiError } from "../../shared";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { setTitle } from "../../hooks/setTitle";

const LoginPage = () => {
  const navigate = useNavigate();
  setTitle("Login");
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onFinish = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        username,
        password,
        rememberMe,
      };

      setLoading(true);

      axios
        .post("/o/ui/login", data)
        .then(function (response) {
          localStorage.setItem("token", response.data.token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
          navigate("/");
        })
        .catch(function (error) {
          setPassword("");
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setValidated(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className='login-background'>
      <Container>
        <Row>
          <Col span={3}></Col>
          <Col span={6} className='login-form'>
            <h1>Login</h1>
            <hr />
            <Form
              onSubmit={onFinish}
              autoComplete='off'
              noValidate
              validated={validated}
            >
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <Form.Text className='text-muted'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Password'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicCheckbox'>
                <Form.Check
                  type='checkbox'
                  label='Remember Me'
                  onChange={() => setRememberMe(!rememberMe)}
                />
              </Form.Group>

              <Button type='submit'>
                {isLoading ? (
                  <Spinner animation='border' variant='light' size='sm' />
                ) : (
                  <>
                    <i className='fa-solid fa-right-to-bracket'></i> &nbsp;
                    Login
                  </>
                )}
              </Button>
            </Form>

            {error && (
              <>
                <br />
                <h6 className='text-danger'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  Invalid credentials
                </h6>
              </>
            )}
          </Col>
          <Col span={3}></Col>
        </Row>
      </Container>
    </div>
  );
};
export default LoginPage;

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes from "../router";
import { useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Navigationbar from "../components/Navigationbar";

const DefaultLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/login") {
      if (localStorage.getItem("token") == null) {
        navigate("/login");
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Navigationbar />
      <Container>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.element />}
              />
            );
          })}
        </Routes>
      </Container>
    </>
  );
};

export default DefaultLayout;

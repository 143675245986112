import React from "react";
import Moment from "react-moment";

interface FuncProps{
    date: string
}

const ControlCustomDate: React.FC<FuncProps> = (props) => {
  return (
    <>
      {props.date ? (
        <Moment format='D MMM YY - hh:mm:ss A' local>
          {props.date}
        </Moment>
      ) : (
        ""
      )}
    </>
  );
};
export default ControlCustomDate;

import axios from "axios";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { JobVM, handleApiError } from "../shared";
import ControlListJobs from "../components/control/ControlListJobs";
import { setTitle } from "../hooks/setTitle";

const JobPage = () => {
  setTitle("Jobs");
  const [agencies, setAgencies] = useState<JobVM[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/job?max=100")
      .then((res) => {
        setAgencies(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>Jobs</h2>
          <hr />

          {isLoading ? (
            <Spinner animation='border' variant='primary' />
          ) : (
            <>
              <ControlListJobs jobs={agencies} maxShown={15} key='allJobs' />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default JobPage;

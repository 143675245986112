import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { CityUiVM, MapVM } from "../shared";
import { setTitle } from "../hooks/setTitle";
import { InputGroup, Spinner } from "react-bootstrap";

const AddEditMap = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const isAddMode = !id;
  const [map, setMap] = useState(MapVM[0]);
  const [agencies, setAgencies] = useState(CityUiVM[0].transitAgencies);
  const [isLoading, setLoading] = useState(false);

  const schema = yup.object().shape({
    agencyId: yup.number().positive("Agency is required"),
    name: yup
      .string()
      .min(4)
      .max(50)
      .required()
      .matches(new RegExp("^[a-zA-Z_ ]+$"), "No special characters allowed")
      .trim(),
    file: yup.mixed(),
    downloadUrl: yup.string().url(),
  });

  const handleSubmit = (event: any) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("pdf", event.file);
    event.file = null;
    formData.append("model", JSON.stringify(event));

    if (isAddMode) {
      axios
        .post("/ui/map/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          navigate("/maps");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .put("/ui/map/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          navigate("/maps");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    axios
      .get("/ui/transitagency/")
      .then((res) => {
        setAgencies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    if (!isAddMode) {
      axios
        .get("/ui/map/" + id)
        .then((res) => {
          setMap(res.data);
          setTitle(res.data.name);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else setTitle("Add Map");
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>{isAddMode ? "Add" : "Update"} Map</h2>
          <hr />
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            initialValues={map}
          >
            {({
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />
                    <Form.Text className='text-muted'>
                      Visible to user in app
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Select File (PDF)</Form.Label>
                    <Form.Control
                      id='file'
                      type='file'
                      name='file'
                      onChange={(event) =>
                        setFieldValue(
                          "file",
                          (event.target as HTMLInputElement).files?.[0] // Add null check
                        )
                      }
                      accept='.pdf'
                    ></Form.Control>
                    {!isAddMode ? (
                      <Form.Text className='text-muted'>
                        Upload new map if needs to be updated.
                      </Form.Text>
                    ) : null}
                  </Form.Group>

                  {!isAddMode ? (
                    <Form.Group as={Col} xs='4'>
                      <br />
                      <Button href={values.signedDownloadUrl} variant='link' target="_blank">
                        <i className='fa-solid fa-download'></i> View Map
                      </Button>
                    </Form.Group>
                  ) : null}
                </Row>
                <Row className='mb-6'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Agency</Form.Label>

                    <Form.Select
                      name='agencyId'
                      value={values.agencyId}
                      onChange={handleChange}
                      isValid={touched.agencyId && !errors.agencyId}
                      isInvalid={!!errors.agencyId}
                    >
                      <option disabled value='0'>
                        None
                      </option>
                      {agencies.map((item, i) => {
                        return (
                          <>
                            <option value={item.id}>{item.name}</option>
                          </>
                        );
                      })}
                    </Form.Select>
                    <Form.Text muted>Showing active agencies.</Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyId}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.downloadUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        name='downloadUrl'
                        value={values.downloadUrl}
                        onChange={handleChange}
                        isValid={touched.downloadUrl && !errors.downloadUrl}
                        isInvalid={!!errors.downloadUrl}
                      />
                    </InputGroup>
                    <Form.Text className='text-muted'>
                      Url from where map can be downloaded.
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.downloadUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Form.Group className='mb-3'></Form.Group>
                <Button type='submit' className='mr-3' disabled={!isValid}>
                  {isLoading ? (
                    <Spinner animation='border' variant='light' size='sm' />
                  ) : (
                    <>
                      <i className='fa-regular fa-floppy-disk'></i>&nbsp;&nbsp;
                      {isAddMode ? "Save" : "Update"}
                    </>
                  )}
                </Button>
                <Button href='/maps' variant='light'>
                  <i className='fa-solid fa-xmark'></i> &nbsp; Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEditMap;

import React, { useEffect, useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { JobVM } from "../../shared";
import moment from "moment";
import ControlCustomDate from "./ControlCustomDate";
import ControlJobStatus from "./ControlJobStatus";

interface FuncProps {
  jobs: JobVM[];
  maxShown: number;
}

const ControlListJobs: React.FC<FuncProps> = (props) => {
  const [jobsFiltered, setJobsFiltered] = useState<JobVM[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(props.maxShown);
  const [maxPages, setMaxPages] = useState(1);

  useEffect(() => {
    setJobsFiltered(props.jobs);
  }, []);

  useEffect(() => {
    setMaxPages(Math.ceil(jobsFiltered.length / props.maxShown));
  }, [jobsFiltered]);

  const onSearch = (event: any) => {
    let text = event.target.value.toLowerCase();
    setActivePage(1);
    if (text.length > 0) {
      let v = props.jobs.filter(
        (item) =>
          item.name.toLowerCase().includes(text) ||
          item.type.toLowerCase().includes(text) ||
          item.status.toLowerCase().includes(text)
      );
      setJobsFiltered(v);
    } else {
      setJobsFiltered(props.jobs);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    setPageStart((pageNumber - 1) * props.maxShown);
    setPageEnd(pageNumber * props.maxShown);
  };

  return (
    <>
      <h6>Search by Name, Type or Status</h6>
      <Form.Control
        type='text'
        placeholder='Search'
        onChange={(e) => onSearch(e)}
      />
      <br />

      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Status</th>
            <th>Start</th>
            <th>Updated</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {jobsFiltered.slice(pageStart, pageEnd).map((item, i) => {
            return (
              <>
                <tr key={i}>
                  <td width={500}>{item.name}</td>
                  <td>{item.type}</td>
                  <td>
                    <ControlJobStatus status={item.status} />
                  </td>
                  <td>
                    <ControlCustomDate date={item.dateStart} />
                  </td>
                  <td>
                    <ControlCustomDate date={item.dateUpdated} />
                  </td>
                  <td>{item.statusDetail}</td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>

      <Pagination className='px-4'>
        {[...Array(maxPages)].map((_, index) => {
          return (
            <Pagination.Item
              onClick={() => handlePageChange(index + 1)}
              key={index + 1}
              active={index + 1 === activePage}
            >
              {index + 1}
            </Pagination.Item>
          );
        })}
      </Pagination>

      <br />
      <h6 className='pull-right'>
        Showing{" "}
        {jobsFiltered.length < props.maxShown
          ? jobsFiltered.length
          : props.maxShown}{" "}
        out of {jobsFiltered.length}
      </h6>
    </>
  );
};

const defaultProps: FuncProps = {
  jobs: [],
  maxShown: 15,
};

ControlListJobs.defaultProps = defaultProps;

export default ControlListJobs;

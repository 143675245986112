import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { handleApiError, MapVM } from "../shared";
import { setTitle } from "../hooks/setTitle";

const MapsPage = () => {
  setTitle("Maps");
  const [maps, setMaps] = useState(MapVM);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/map")
      .then((res) => {
        setMaps(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>
            Maps
            <Button href='/maps/add' className='pull-right'>
              <i className='fa-solid fa-plus'></i> Add
            </Button>
          </h2>

          <hr />
          {isLoading ? (
            <Spinner animation='border' variant='primary' />
          ) : (
            <>
              <Table hover>
                <thead>
                  <tr>
                    <th>Agency</th>
                    <th>Name</th>
                    <th>File name</th>
                  </tr>
                </thead>
                <tbody>
                  {maps?.map((item, i) => (
                    <>
                      <tr>
                        <td>
                          <a href={"/maps/edit/" + item.id}>
                            {item.agencyName}
                          </a>
                        </td>
                        <td>{item.name}</td>
                        <td>{item.fileName}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MapsPage;

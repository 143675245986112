import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import LoginPage from "./pages/login/Login";
import DefaultLayout from "./layout/Layout";
import axios from "axios";
import { Config } from "./config"; 

function App() {
  axios.defaults.baseURL = Config.API_URL;
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("token");
  axios.defaults.headers.common['X-Pragma'] =  Config.X_PRAGMA;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={<DefaultLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

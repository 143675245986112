import axios from "axios";
import * as formik from "formik";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Alert,
  InputGroup,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AgencyLicense, AgencyVMs, AuthType, Countries } from "../shared";
import * as yup from "yup";
import { setTitle } from "../hooks/setTitle";

const AddEditAgency = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = !id;
  const [agency, setAgency] = useState(AgencyVMs[0]);
  const { Formik } = formik;
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isAddMode) {
      axios
        .get("/ui/agency/" + id)
        .then((res) => {
          setAgency(res.data);
          setTitle(res.data.agencyName);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else setTitle("Add Agency");
  }, []);

  const schema = yup.object().shape({
    agencyId: yup
      .string()
      .min(2)
      .max(150)
      .required()
      .matches(new RegExp("^[a-zA-Z_]+$"), "No special characters allowed")
      .trim(),
    agencyIdGtfs: yup
      .string()
      // .matches(
      //   new RegExp("^(?!.*;$)(?!.*[^a-zA-Z0-9;_s-s ]).*?(?:;[a-zA-Z0-9s-]+)*$"),
      //   "Only semi-colon seprated values allowed"
      // )
      .max(1000),
    agencyName: yup.string().max(200).required(),
    agencyUrl: yup.string().url().max(600).required(),
    agencyTimezone: yup
      .string()
      .matches(
        new RegExp("^[a-zA-Z0-9_+-]+/[a-zA-Z0-9_+-]+$"),
        "Not valid timezone"
      )
      .max(200)
      .required(),
    agencyLang: yup.string().max(150).required(),
    agencyPhone: yup.string().max(200),
    agencyFareUrl: yup.string().max(600).url(),
    agencyEmail: yup.string().max(350).email(),
    agencyColor: yup
      .string()
      .min(6)
      .max(6)
      .required()
      .notOneOf(
        [yup.ref("agencyTextColor"), null],
        "Color and Text color can not be same."
      ),

    agencyTextColor: yup
      .string()
      .min(6)
      .max(6)
      .required()
      .notOneOf(
        [yup.ref("agencyColor"), null],
        "Color and Text color can not be same."
      ),
    agencyFriendlyName: yup.string().max(125).required(),
    gtfsStaticDataUrl: yup
      .string()
      .matches(
        new RegExp('^(ftp|http|https)://[^";]+(;(ftp|http|https)://[^";]+)*$'),
        "Only semi-colon seprated values allowed"
      )
      .max(1000),
    gtfsRTTripupdateUrl: yup.string().max(600).url().nullable(),
    gtfsRTVehicleUrl: yup.string().max(600).url().nullable(),
    gtfsRTAlertUrl: yup.string().max(600).url().nullable(),
    apiKeyHeader: yup.string().max(50),
    apiKey: yup.string().max(199),
    country: yup.string().required().max(50),
    state: yup.string().required().max(50),
    city: yup.string().max(50),
  });

  const handleSubmit = (event: any) => {
    setLoading(true);
    if (isAddMode) {
      axios
        .post("/ui/agency/create", event)
        .then((res) => {
          navigate("/agencies/view/" + res.data.id);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .put("/ui/agency/update", event)
        .then((res) => {
          navigate("/agencies/view/" + event.id);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onDelete = (event: any) => {
    if (
      window.confirm(
        "Would you like to delete " + agency.agencyName + " agency?"
      )
    ) {
      setLoading(true);
      axios
        .delete("/ui/agency/delete?id=" + agency.id)
        .then((res) => {
          navigate("/agencies");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getAgencyInfo = (e: any, values: any, setValues: any) => {
    axios
      .get("/ui/agency/transitland/" + values.onestopId)
      .then((res) => {
        setValues({
          ...values,
          developerUrl: res.data.developerUrl,
          licenseUrl: res.data.licenseUrl,
          licenseCommercialUseAllowed: res.data.licenseCommercialUseAllowed,
          licenseShareAlikeOptional: res.data.licenseShareAlikeOptional,
          licenseCreateDerivedProduct: res.data.licenseCreateDerivedProduct,
          licenseRedistributionAllowed: res.data.licenseRedistributionAllowed,
          licenseUseWithoutAttribution: res.data.licenseUseWithoutAttribution,
          licenseAttributionText: res.data.licenseAttributionText,
          licenseAttributionInstructions:
            res.data.licenseAttributionInstructions,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function removeSpecialCharsAndSpaces(str: string) {
    // Replace all special characters and spaces with an empty string
    return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  }

  function getInternalAgencyName(
    country: string,
    state: string,
    friendlyName: string
  ) {
    const selectedCountry = Countries.find((c) => c.name === country);
    const countryCode = selectedCountry ? selectedCountry.iso2 : "";
    return (
      removeSpecialCharsAndSpaces(countryCode) +
      "_" +
      removeSpecialCharsAndSpaces(state) +
      "_" +
      removeSpecialCharsAndSpaces(friendlyName)
    );
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={agency}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setValues,
        }) => (
          <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
            <div className='card'>
              <div className='card-body'>
                <h2>{isAddMode ? "Add" : "Update"} Agency</h2>
                <hr />
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Internal Id</Form.Label>
                    <InputGroup>
                      <InputGroup.Checkbox
                        onChange={handleChange}
                        name='isAgencyIdActive'
                        checked={values.isAgencyIdActive}
                      />
                      <Form.Control
                        type='text'
                        disabled={!isAddMode || !values.isAgencyIdActive}
                        name='agencyId'
                        value={values.agencyId}
                        onChange={handleChange}
                        isValid={touched.agencyId && !errors.agencyId}
                        isInvalid={!!errors.agencyId}
                      />
                    </InputGroup>
                    <Form.Text className='text-muted'>
                      Editing now allowed. Underscore allowed
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyId}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Gtfs Feed Id</Form.Label>
                    <Form.Control
                      type='text'
                      name='agencyIdGtfs'
                      value={values.agencyIdGtfs}
                      onChange={handleChange}
                      isValid={touched.agencyIdGtfs && !errors.agencyIdGtfs}
                      isInvalid={!!errors.agencyIdGtfs}
                    />
                    <Form.Text className='text-muted'>
                      Multiple agency id allowed. Separate by semi-colon (;)
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyIdGtfs}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='agencyName'
                      value={values.agencyName}
                      onChange={handleChange}
                      isValid={touched.agencyName && !errors.agencyName}
                      isInvalid={!!errors.agencyName}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='3'>
                    <Form.Label>Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.agencyUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        name='agencyUrl'
                        value={values.agencyUrl}
                        onChange={handleChange}
                        isValid={touched.agencyUrl && !errors.agencyUrl}
                        isInvalid={!!errors.agencyUrl}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.agencyUrl}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Timezone</Form.Label>
                    <Form.Control
                      type='text'
                      name='agencyTimezone'
                      value={values.agencyTimezone}
                      onChange={handleChange}
                      isValid={touched.agencyTimezone && !errors.agencyTimezone}
                      isInvalid={!!errors.agencyTimezone}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyTimezone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Friendly Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='agencyFriendlyName'
                      value={values.agencyFriendlyName}
                      onChange={(e) => {
                        if (!isAddMode) return;
                        setValues({
                          ...values,
                          agencyId: getInternalAgencyName(
                            values.country,
                            values.state,
                            e.target.value
                          ),
                        });
                      }}
                      isValid={
                        touched.agencyFriendlyName && !errors.agencyFriendlyName
                      }
                      isInvalid={!!errors.agencyFriendlyName}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyFriendlyName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Language</Form.Label>

                    <Form.Select
                      name='agencyLang'
                      value={values.agencyLang}
                      onChange={handleChange}
                      isValid={touched.agencyLang && !errors.agencyLang}
                      isInvalid={!!errors.agencyLang}
                    >
                      <option value=''></option>
                      <option value='en'>English</option>
                      <option value='fr'>French</option>
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyLang}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Color</Form.Label>

                    <InputGroup>
                      <InputGroup.Text>
                        <div
                          className='colorBox'
                          style={{ background: "#" + values.agencyColor }}
                        ></div>
                      </InputGroup.Text>
                      <Form.Control
                        type='text'
                        name='agencyColor'
                        value={values.agencyColor}
                        onChange={handleChange}
                        maxLength={7}
                        isValid={touched.agencyColor && !errors.agencyColor}
                        isInvalid={!!errors.agencyColor}
                      />

                      <Form.Control.Feedback type='invalid'>
                        {errors.agencyColor}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Text Color</Form.Label>

                    <InputGroup>
                      <InputGroup.Text>
                        <div
                          className='colorBox'
                          style={{
                            background: "#" + values.agencyTextColor,
                          }}
                        ></div>
                      </InputGroup.Text>
                      <Form.Control
                        type='text'
                        name='agencyTextColor'
                        value={values.agencyTextColor}
                        maxLength={7}
                        onChange={handleChange}
                        isValid={
                          touched.agencyTextColor && !errors.agencyTextColor
                        }
                        isInvalid={!!errors.agencyTextColor}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.agencyTextColor}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Otp Instance</Form.Label>

                    <Form.Select
                      disabled={true}
                      name='agencyLang'
                      value={values.otpInstanceId}
                    >
                      <option value={values.otpInstanceId}>
                        {values.otpInstanceName ?? "-- Not available --"}
                      </option>
                    </Form.Select>

                    <Form.Text className='text-muted'>
                      You can assign this agency to an Otp Instance from it's
                      page
                    </Form.Text>
                  </Form.Group>
                </Row>
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <h5>License Info</h5>
                <hr />
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>OneStop Id</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        name='onestopId'
                        id='onestopId'
                        value={values.onestopId}
                        onChange={handleChange}
                        isValid={touched.onestopId && !errors.onestopId}
                        isInvalid={!!errors.onestopId}
                      />
                      <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>Load info from Transitland</Tooltip>}
                      >
                        <Button
                          variant='outline-secondary'
                          id='button-addon2'
                          onClick={(e) => getAgencyInfo(e, values, setValues)}
                        >
                          Fetch
                        </Button>
                      </OverlayTrigger>
                    </InputGroup>

                    <Form.Control.Feedback type='invalid'>
                      {errors.onestopId}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                      name='country'
                      value={values.country}
                      onChange={(e) => {
                        if (!isAddMode) return;
                        setValues({
                          ...values,
                          agencyId: getInternalAgencyName(
                            e.target.value,
                            values.state,
                            values.agencyFriendlyName
                          ),
                        });
                      }}
                      isValid={touched.country && !errors.country}
                      isInvalid={!!errors.country}
                    >
                      <option value=''></option>
                      {Countries.map((item, i) => {
                        return (
                          <>
                            <option value={item.name}>{item.name}</option>
                          </>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {errors.country}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type='text'
                      name='state'
                      value={values.state}
                      onChange={(e) => {
                        if (!isAddMode) return;
                        setValues({
                          ...values,
                          agencyId: getInternalAgencyName(
                            values.country,
                            e.target.value,
                            values.agencyFriendlyName
                          ),
                        });
                      }}
                      isValid={touched.state && !errors.state}
                      isInvalid={!!errors.state}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type='text'
                      name='city'
                      value={values.city}
                      onChange={handleChange}
                      isValid={touched.city && !errors.city}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                      type='text'
                      name='latitude'
                      value={values.latitude}
                      onChange={handleChange}
                      isValid={touched.latitude && !errors.latitude}
                      isInvalid={!!errors.latitude}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.latitude}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      type='text'
                      name='longitude'
                      value={values.longitude}
                      onChange={handleChange}
                      isValid={touched.longitude && !errors.longitude}
                      isInvalid={!!errors.longitude}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.longitude}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='6'>
                    <Form.Label>Developer Portal Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.developerUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        name='developerUrl'
                        value={values.developerUrl}
                        onChange={handleChange}
                        isValid={touched.developerUrl && !errors.developerUrl}
                        isInvalid={!!errors.developerUrl}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type='invalid'>
                      {errors.developerUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='6'>
                    <Form.Label>License Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.licenseUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        name='licenseUrl'
                        value={values.licenseUrl}
                        onChange={handleChange}
                        isValid={touched.licenseUrl && !errors.licenseUrl}
                        isInvalid={!!errors.licenseUrl}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type='invalid'>
                      {errors.licenseUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Commercial Use Allowed</Form.Label>
                    <Form.Select
                      name='licenseCommercialUseAllowed'
                      value={values.licenseCommercialUseAllowed}
                      onChange={handleChange}
                    >
                      {AgencyLicense.map((item, i) => {
                        return <option value={i}>{item}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Share Alike</Form.Label>
                    <Form.Select
                      name='licenseShareAlikeOptional'
                      value={values.licenseShareAlikeOptional}
                      onChange={handleChange}
                    >
                      {AgencyLicense.map((item, i) => {
                        return <option value={i}>{item}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Create Derived Product</Form.Label>
                    <Form.Select
                      name='licenseCreateDerivedProduct'
                      value={values.licenseCreateDerivedProduct}
                      onChange={handleChange}
                    >
                      {AgencyLicense.map((item, i) => {
                        return <option value={i}>{item}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Redistribution Allowed</Form.Label>
                    <Form.Select
                      name='licenseRedistributionAllowed'
                      value={values.licenseRedistributionAllowed}
                      onChange={handleChange}
                    >
                      {AgencyLicense.map((item, i) => {
                        return <option value={i}>{item}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Use Without Attribution</Form.Label>
                    <Form.Select
                      name='licenseUseWithoutAttribution'
                      value={values.licenseUseWithoutAttribution}
                      onChange={handleChange}
                    >
                      {AgencyLicense.map((item, i) => {
                        return <option value={i}>{item}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='6'>
                    <Form.Label>Attribution Text</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='licenseAttributionText'
                      value={values.licenseAttributionText}
                      onChange={handleChange}
                      isValid={
                        touched.licenseAttributionText &&
                        !errors.licenseAttributionText
                      }
                      isInvalid={!!errors.licenseAttributionText}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.licenseAttributionText}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='6'>
                    <Form.Label>Attribution Instructions</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='licenseAttributionInstructions'
                      value={values.licenseAttributionInstructions}
                      onChange={handleChange}
                      isValid={
                        touched.licenseAttributionInstructions &&
                        !errors.licenseAttributionInstructions
                      }
                      isInvalid={!!errors.licenseAttributionInstructions}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.licenseAttributionInstructions}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <h5>Gtfs</h5>
                <hr />

                <Row className='mb-6'>
                  <Form.Group as={Col} xs='10'>
                    <Form.Label>Gtfs Static Data Url</Form.Label>

                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.gtfsStaticDataUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        name='gtfsStaticDataUrl'
                        value={values.gtfsStaticDataUrl}
                        onChange={handleChange}
                        isValid={
                          touched.gtfsStaticDataUrl && !errors.gtfsStaticDataUrl
                        }
                        isInvalid={!!errors.gtfsStaticDataUrl}
                      />
                    </InputGroup>
                    <Form.Text className='text-muted'>
                      Multiple url's allowed. Separate by semi-colon (;)
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.gtfsStaticDataUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='2'>
                    <Form.Label>Auth Type</Form.Label>

                    <Form.Select
                      name='gtfsStaticDataUrlAuth'
                      value={values.gtfsStaticDataUrlAuth}
                      onChange={handleChange}
                    >
                      <option value={AuthType.None}>None</option>
                      <option value={AuthType.QueryString}>QueryString</option>
                      <option value={AuthType.Header}>Http header</option>
                      <option value={AuthType.Both}>
                        QueryString & Header
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Check
                      type='switch'
                      name='isRealTimeDataAvailable'
                      checked={values.isRealTimeDataAvailable}
                      onChange={handleChange}
                      label='Real-time Data Availabe'
                    />
                    <br />
                    <Form.Check
                      type='switch'
                      disabled={!values.isRealTimeDataAvailable}
                      name='isGtfsRealtimeDataAvailable'
                      checked={values.isGtfsRealtimeDataAvailable}
                      onChange={handleChange}
                      label='Gtfs Real-time Data Availabe'
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Check
                      type='switch'
                      name='isRealTimeVehicleDataAvailable'
                      checked={values.isRealTimeVehicleDataAvailable}
                      onChange={handleChange}
                      label='Real-time Vehicle Data Availabe'
                    />
                    <br />
                    <Form.Check
                      type='switch'
                      name='isRouteServiceAlertAvailable'
                      checked={values.isRouteServiceAlertAvailable}
                      onChange={handleChange}
                      label='Route service alerts Availabe'
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                  <Form.Label>Gtfs Realtime Auth Type</Form.Label>
                    <Form.Select
                      name='gtfsRealtimeDataAuth'
                      disabled={!values.isGtfsRealtimeDataAvailable}
                      value={values.gtfsRealtimeDataAuth}
                      onChange={handleChange}
                    >
                      <option value={AuthType.None}>None</option>
                      <option value={AuthType.QueryString}>QueryString</option>
                      <option value={AuthType.Header}>Http header</option>
                      <option value={AuthType.Both}>
                        QueryString & Header
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Gtfs-RT TripUpdate Url</Form.Label>

                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.gtfsRTTripupdateUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        disabled={!values.isGtfsRealtimeDataAvailable}
                        name='gtfsRTTripupdateUrl'
                        value={values.gtfsRTTripupdateUrl}
                        onChange={handleChange}
                        isValid={
                          touched.gtfsRTTripupdateUrl &&
                          !errors.gtfsRTTripupdateUrl
                        }
                        isInvalid={!!errors.gtfsRTTripupdateUrl}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type='invalid'>
                      {errors.gtfsRTTripupdateUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Gtfs-RT Vehicle Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.gtfsRTVehicleUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        disabled={!values.isGtfsRealtimeDataAvailable}
                        name='gtfsRTVehicleUrl'
                        value={values.gtfsRTVehicleUrl}
                        onChange={handleChange}
                        isValid={
                          touched.gtfsRTVehicleUrl && !errors.gtfsRTVehicleUrl
                        }
                        isInvalid={!!errors.gtfsRTVehicleUrl}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type='invalid'>
                      {errors.gtfsRTVehicleUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Gtfs-RT Alert Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.gtfsRTAlertUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        disabled={!values.isGtfsRealtimeDataAvailable}
                        name='gtfsRTAlertUrl'
                        value={values.gtfsRTAlertUrl}
                        onChange={handleChange}
                        isValid={
                          touched.gtfsRTAlertUrl && !errors.gtfsRTAlertUrl
                        }
                        isInvalid={!!errors.gtfsRTAlertUrl}
                      />
                    </InputGroup>

                    <Form.Control.Feedback type='invalid'>
                      {errors.gtfsRTAlertUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <h5>Optional</h5>
                <hr />
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='text'
                      name='agencyEmail'
                      value={values.agencyEmail}
                      onChange={handleChange}
                      isValid={touched.agencyEmail && !errors.agencyEmail}
                      isInvalid={!!errors.agencyEmail}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyEmail}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Fare Url</Form.Label>
                    <InputGroup>
                      <a
                        className='btn btn-outline-secondary'
                        role='button'
                        href={values.agencyFareUrl}
                        target='_blank'
                      >
                        <i className='fa-solid fa-globe'></i>
                      </a>
                      <Form.Control
                        type='text'
                        name='agencyFareUrl'
                        value={values.agencyFareUrl}
                        onChange={handleChange}
                        isValid={touched.agencyFareUrl && !errors.agencyFareUrl}
                        isInvalid={!!errors.agencyFareUrl}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyFareUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type='text'
                      name='agencyPhone'
                      value={values.agencyPhone}
                      onChange={handleChange}
                      isValid={touched.agencyPhone && !errors.agencyPhone}
                      isInvalid={!!errors.agencyPhone}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.agencyPhone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className='mb-6'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Data source</Form.Label>
                    <Form.Select
                      name='datasource'
                      value={values.datasource}
                      onChange={handleChange}
                    >
                      <option value='0'>Nextbus</option>
                      <option value='2'>Server</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Api Key Header</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={!values.isRealTimeDataAvailable}
                      name='apiKeyHeader'
                      value={values.apiKeyHeader}
                      onChange={handleChange}
                      isValid={touched.apiKeyHeader && !errors.apiKeyHeader}
                      isInvalid={!!errors.apiKeyHeader}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.apiKeyHeader}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Api Key</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={!values.isRealTimeDataAvailable}
                      name='apiKey'
                      value={values.apiKey}
                      onChange={handleChange}
                      isValid={touched.apiKey && !errors.apiKey}
                      isInvalid={!!errors.apiKey}
                    />
                    <Form.Text className='text-muted'>
                      Api key will be appended into query string and http
                      header.
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.apiKey}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <Row className='mb-3'>
                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Status</Form.Label>

                    <Form.Select
                      name='status'
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value='0'>Empty</option>
                      <option value='1'>Updating</option>
                      <option value='2'>Valid</option>
                      <option value='3'>Error</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} xs='4'>
                    <Form.Label>Twitter Id</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={values.isCustomTransitAlert}
                      name='twitterId'
                      value={values.twitterId}
                      onChange={handleChange}
                      isValid={touched.twitterId && !errors.twitterId}
                      isInvalid={!!errors.twitterId}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.twitterId}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} xs='4'>
                    <br />
                    <Form.Check
                      type='switch'
                      name='isCustomTransitAlert'
                      checked={values.isCustomTransitAlert}
                      onChange={handleChange}
                      label='Custom Transit Alert'
                    />

                    <Form.Text className='text-muted'>
                      If this is checked, app shows service alerts. If not
                      checked, it shows tweets if filled.
                    </Form.Text>
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group
                    as={Col}
                    xs='8'
                    className={isAddMode ? "hidden" : ""}
                  >
                    <Form.Check
                      type='switch'
                      name='isUpdateGtfsProcesssedFile'
                      checked={values.isUpdateGtfsProcesssedFile}
                      onChange={handleChange}
                      label='Schedule GtfsProcessedFile update (Can also update OtpGraph)'
                    />

                    <Form.Check
                      type='switch'
                      name='isForceDataUpdateNow'
                      checked={values.isForceDataUpdateNow}
                      onChange={handleChange}
                      label='Force Gtfs Data Update Now'
                    />
                    <Form.Check
                      type='switch'
                      name='isActive'
                      checked={values.isActive}
                      onChange={handleChange}
                      label='Active (Visible to users)'
                    />
                  </Form.Group>
                </Row>
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <Button type='submit' className='mr-3' disabled={!isValid}>
                  {isLoading ? (
                    <Spinner animation='border' variant='light' size='sm' />
                  ) : (
                    <>
                      <i className='fa-regular fa-floppy-disk'></i>&nbsp;&nbsp;
                      {isAddMode ? "Save" : "Update"}
                    </>
                  )}
                </Button>
                <Button href='/agencies' className='mr-3' variant='light'>
                  <i className='fa-solid fa-xmark'></i> &nbsp; Cancel
                </Button>
                {isAddMode ? (
                  ""
                ) : (
                  <Button
                    type='button'
                    className='mr-3'
                    variant='danger'
                    onClick={onDelete}
                  >
                    {isLoading ? (
                      <Spinner animation='border' variant='light' size='sm' />
                    ) : (
                      <>
                        <i className='fa-regular fa-trash-can'></i> &nbsp;
                        Delete
                      </>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {error && <div className='alert alert-danger'>{error}</div>}
    </>
  );
};
export default AddEditAgency;

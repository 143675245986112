import React from "react";
import { Badge } from "react-bootstrap";
import Moment from "react-moment";

interface FuncProps {
  status: number;
}

const ControlAgencyStatus: React.FC<FuncProps> = (props) => {
  return (
    <>
      {(() => {
        switch (props.status) {
          case 0:
            return <Badge bg="secondary">Empty</Badge>;
          case 1:
            return <Badge bg="warning" text="dark">Updating</Badge>;
          case 2:
            return <Badge bg="success">Valid</Badge>;
            case 3:
            return <Badge bg="danger">Error</Badge>;
        }
      })()}
    </>
  );
};
export default ControlAgencyStatus;

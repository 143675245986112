import React from "react";
import { Badge } from "react-bootstrap";
import Moment from "react-moment";

interface FuncProps {
  status: string;
}

const ControlJobStatus: React.FC<FuncProps> = (props) => {
  return (
    <>
      {(() => {
        switch (props.status) {
          case "created":
            return <Badge bg="secondary">CREATED</Badge>;
          case "processing":
            return <Badge bg="warning" text="dark">PROCESSING</Badge>;
          case "completed":
            return <Badge bg="success">COMPLETED</Badge>;
          case "failed":
            return <Badge bg="danger">FAILED</Badge>;
          case "cancelled":
            return <Badge bg="dark">CANCELLED</Badge>;
          default:
            return <Badge bg="info">{props.status}</Badge>;
        }
      })()}
    </>
  );
};
export default ControlJobStatus;

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import routes from "../router";
import { logOut } from "../shared";
import { Config } from "../config";

const Navigationbar = () => {
  return (
    <>
      {Config.IS_DEV && <div className="text-center">Development environment</div>}
      <Navbar
        bg={Config.IS_DEV ? "development" : "primary"}
        variant='dark'
        expand='lg'
        sticky='top'
      >
        <Container>
          <Navbar.Brand href='/'>Transit</Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {routes.map((route, index) => {
                if (route.showInBar)
                  return <Nav.Link href={route.path}>{route.name}</Nav.Link>;
              })}
            </Nav>
          </Navbar.Collapse>

          <Nav className='justify-content-end' activeKey='/home'>
            <Nav.Item>
              <Nav.Link href='/home' onClick={(e) => logOut()}>
                Log out
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigationbar;

import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Table, Spinner } from "react-bootstrap";
import ControlTableSwitch from "../components/control/ControlTableSwitch";
import { handleApiError, OtpInstanceVM } from "../shared";
import { setTitle } from "../hooks/setTitle";

const OtpInstancePage = () => {
  setTitle("Otp Instances");
  const [otpInstances, setInstances] = useState(OtpInstanceVM);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/ui/otpinstance")
      .then((res) => {
        setInstances(res.data);
      })
      .catch((err) => {
        handleApiError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>
            Otp Instance
            <Button href='/otpinstance/add' className='pull-right'>
              <i className='fa-solid fa-plus'></i> Add
            </Button>
          </h2>
          <p>
            You can create new open trip planner instance here. This doesn't
            deploy new k8 pod, that's a manual process.
          </p>
          <hr />

          {isLoading ? (
            <Spinner animation='border' variant='primary' />
          ) : (
            <>
              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Timezone</th>
                    <th>Url</th>
                    <th>Otp Memory</th>
                    <th>Graph Memory</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {otpInstances.map((item, i) => {
                    return (
                      <>
                        <tr>
                          <td>
                            {" "}
                            <a href={"/otpinstance/edit/" + item.id}>
                              {item.name}
                            </a>
                          </td>
                          <td>{item.timezone}</td>
                          <td>{item.url}</td>
                          <td>{item.otpMemory} MB</td>
                          <td>{item.graphMemory} MB</td>
                          <td>
                            <ControlTableSwitch status={item.isActive} />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default OtpInstancePage;

import React, { useEffect, useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { AgencyVM } from "../../shared";
import moment from "moment";
import ControlAgencyStatus from "./ControlAgencyStatus";
import ControlCustomDate from "./ControlCustomDate";

interface FuncProps {
  agencies: AgencyVM[];
  maxShown: number;
}

const ControlListAgencies: React.FC<FuncProps> = (props) => {
  const [agenciesFiltered, setAgenciesFiltered] = useState<AgencyVM[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(props.maxShown);
  const [maxPages, setMaxPages] = useState(1);

  useEffect(() => {
    setAgenciesFiltered(props.agencies);
  }, []);

  useEffect(() => {
    setMaxPages(Math.ceil(agenciesFiltered.length / props.maxShown));
  }, [agenciesFiltered]);

  const getTableDecoration = (date: any) => {
    if (date) {
      if (moment(date).isBefore()) return "table-danger";
      if (moment(date).diff(moment.utc(), "days") <= 3) return "table-warning";
      else return "";
    }
    return "table-danger";
  };

  const onSearch = (event: any) => {
    let text = event.target.value.toLowerCase();
    setActivePage(1);
    if (text.length > 0) {
      let v = props.agencies.filter(
        (item) =>
          item.agencyName.toLowerCase().includes(text) ||
          item.agencyId.toLowerCase().includes(text) ||
          item.agencyFriendlyName.toLowerCase().includes(text) ||
          item.onestopId.toLowerCase().includes(text)
      );
      setAgenciesFiltered(v);
    } else {
      setAgenciesFiltered(props.agencies);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    setPageStart((pageNumber - 1) * props.maxShown);
    setPageEnd(pageNumber * props.maxShown);
  };

  return (
    <>
      <h6>Search by Name, Friendly Name or Onestop Id</h6>
      <Form.Control
        type='text'
        placeholder='Search'
        onChange={(e) => onSearch(e)}
      />
      <br />

      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Friendly Name</th>
            <th>Status</th>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Last Updated</th>
            <th>Data End</th>
          </tr>
        </thead>
        <tbody>
          {agenciesFiltered.slice(pageStart, pageEnd).map((item, i) => {
            return (
              <>
                <tr className={getTableDecoration(item.dataEndDate)} key={i}>
                  <td>
                    <a href={"/agencies/view/" + item.id}>{item.agencyName}</a>
                  </td>
                  <td>{item.agencyFriendlyName}</td>
                  <td>
                    <ControlAgencyStatus status={item.status} />
                  </td>
                  <td>{item.country}</td>
                  <td>{item.state}</td>
                  <td>{item.city}</td>
                  <td>
                    <ControlCustomDate date={item.lastUpdated} />
                  </td>
                  <td>
                    <ControlCustomDate date={item.dataEndDate} />
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>

      <Pagination className='px-4'>
        {[...Array(maxPages)].map((_, index) => {
          return (
            <Pagination.Item
              onClick={() => handlePageChange(index + 1)}
              key={index + 1}
              active={index + 1 === activePage}
            >
              {index + 1}
            </Pagination.Item>
          );
        })}
      </Pagination>

      <br />
      <h6 className='pull-right'>
        Showing{" "}
        {agenciesFiltered.length < props.maxShown
          ? agenciesFiltered.length
          : props.maxShown}{" "}
        out of {agenciesFiltered.length}
      </h6>
    </>
  );
};

const defaultProps: FuncProps = {
  agencies: [],
  maxShown: 15,
};

ControlListAgencies.defaultProps = defaultProps;

export default ControlListAgencies;

import React from "react";
import { Badge, Form } from "react-bootstrap";
import Moment from "react-moment";

interface FuncProps {
  status: boolean;
  readonly?: boolean;
}

const ControlTableSwitch: React.FC<FuncProps> = (props) => {
  return (
    <>
      <Form.Check
        checked={props.status}
        readOnly={props.readonly}
        type='switch'
      />
    </>
  );
};
export default ControlTableSwitch;
